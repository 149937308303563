import { ApiService } from '@/services/ApiService';
import { WebsiteEditModel } from '@/services/website/model/WebsiteEditModel';
import { AppSettings } from '../AppSettings';

export class WebsiteService {
        
    api : ApiService = new ApiService();
    
    List(showActiveOnly:boolean)
    {
        return this.api.get(AppSettings.ApiUrl + '/api/website?activeOnly=' + showActiveOnly);
    }

    Get(websiteId:string)
    {
        return this.api.get(AppSettings.ApiUrl +'/api/website?websiteId='+websiteId);
    }
    
    Create(model:WebsiteEditModel)
    {
        return this.api.post(AppSettings.ApiUrl +'/api/website', model);
    }

    Update(model:WebsiteEditModel)
    {
        return this.api.put(AppSettings.ApiUrl +'/api/website', model);
    }

    LastResponse(websiteId:string)
    {
        return this.api.get(AppSettings.ApiUrl +'/api/website-lastresponse?websiteId='+websiteId);
    }

    ResponseByDate(websiteId:string, responseTime:Date )
    {
        return this.api.get(AppSettings.ApiUrl +'/api/website-response?websiteId='+websiteId+'?responseTime=' + responseTime.toISOString());
    }
}
