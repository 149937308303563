
import { Component, Vue } from 'vue-property-decorator';
import container from '@/services/ServiceLocator';
import { AppSettings } from '@/services/AppSettings';
@Component({})
export default class App extends Vue {
    AllWebsites: any = [];
    Websites: any = [];
    Templates: any = [];
    navActive = false;
    showActiveWebsites = false;
    version = AppSettings.Version;


    async getUserInfo() {
        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;


        localStorage.setItem('userId', clientPrincipal.userId);
        localStorage.setItem('userName', clientPrincipal.userDetails);
        localStorage.setItem('identityProvider', clientPrincipal.identityProvider);

        return clientPrincipal;
    }

    loggedIn(): string {
        return localStorage.getItem('userId');
    }
    async Logout() {
        container.Cache.clear();
        localStorage.clear();

        await fetch('/.auth/logout');
    }

    created() { // warning this is specail name!
        this.showActiveWebsites = localStorage.getItem('showActiveWebsites') == 'true';
        this.getUserInfo();
        this.List();
    }

    List() {
        localStorage.setItem('showActiveWebsites', this.showActiveWebsites ? 'true' : 'false');
        let temp = localStorage.getItem('TemplatesWithWebsites');
        if (temp) {
            this.Templates = JSON.parse(temp);
        }

        container.Website.List(this.showActiveWebsites).then((rw) => {

            this.AllWebsites = rw.data;
            //localStorage.setItem('AllWebsites', JSON.stringify(rw.data));

            container.Template.List().then((r) => {
                //console.log('Template.List resp: ', r);
                this.Templates = r.data;

                for (const t of this.Templates) {

                    t.websites = this.AllWebsites.filter(w => w.websiteTemplateId == t.id);
                }
                localStorage.setItem('TemplatesWithWebsites', JSON.stringify(this.Templates));
            });
        });

    }
}
