
export class CacheService {
    clear(){  
        localStorage.removeItem('cache_list'); 
    }

    setObject(key:string, val:any, timeInMunutes:number)
    {
        let list: any = {};
        const slist = localStorage.getItem('cache_list');
        
        if(slist)
        {
            list = JSON.parse(slist);
        } 
        const expt = new Date(new Date().getTime() + timeInMunutes * 60000);
        const record = { expirationDate:expt.getTime(), data:JSON.stringify(val) };
        //console.log('cache.Set2', record);
        list[key] =  record;
        localStorage.setItem('cache_list', JSON.stringify(list));
    }

    get(key:string)
    {
        const slist = localStorage.getItem('cache_list');
        if(slist)
        {
            const list = JSON.parse(slist);
            const record = list[key];
            if(record)
            {
                //console.log('record', record);
                if(new Date().getTime() < record.expirationDate )
                {
                    //console.log('cache.get', key, record);
                    return JSON.parse(record.data);
                }
            }
            
        } 
        return null;
    }
}
