import { ApiService } from '@/services/ApiService';
import { AppSettings } from '@/services/AppSettings';
import { TemplateModel } from '@/services/website/model/TemplateModel';
import { CacheService } from '@/services/CacheService';
import { VariableModel } from '@/services/website/model/VariableModel';

export class TemplateService {
        
    api : ApiService = new ApiService();
    cache : CacheService = new CacheService();
    List()
    {
        //return this.api.getWithCache(AppSettings.ApiUrl + 'template/list', 'TemplateList', 10);
        return this.api.get(AppSettings.ApiUrl + '/api/template-list');
    }

    Get(templateId:number)
    {
        return this.api.get(AppSettings.ApiUrl + 'template/'+templateId);
    }
    
    // Create(model:TemplateModel)
    // {
    //     return this.api.post(AppSettings.ApiUrl + 'template/create', model);
    // }

    // Update(model:TemplateModel)
    // {
    //     return this.api.put(AppSettings.ApiUrl + 'template/update', model);
    // }



    VariableList(templateId:number)
    {
        return this.api.get('/api/template-variables?templateId='+templateId);
    }

    VariableCreate(model:VariableModel)
    {
        return this.api.post('/api/template-variable', model);
    }

    VariableUpdate(model:VariableModel)
    {
        return this.api.put('/api/template-variable', model);
    }

    VariableDelete(varId:number)
    {
        return this.api.delete('/api/template-variable?variableId='+varId);
    }

    VariableValidate(websiteId:string, model:VariableModel)
    {
        return this.api.post('/api/template-variable-validate?websiteId=' + websiteId, model);
    }

    VariableBuildDataHistory(websiteId:string, model:VariableModel)
    {
        return this.api.post(AppSettings.ApiUrl + 'template/variable/BuildDataHistory?websiteId=' + websiteId, model);
    }
}
