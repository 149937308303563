import axios from 'axios';
import { AppSettings } from '@/services/AppSettings';

export class AuthService {
    
    Login(username:string, password:string)
    {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', username);
        params.append('password', password);

        const options = { 
            headers:{
                'content-type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic QW5ndWxhckNsaWVudDpzZWNyZXQ=' // this is clientId & client secret - do not remove that!
            },
        };

        return axios.post(AppSettings.ApiUrl + 'connect/token', params, options).then((result)=>{
            localStorage.setItem('ACCESS_TOKEN',result.data.access_token);
            const expires_in = result.data.expires_in;
            const now = new Date();
            const expiryDate = new Date(now.getTime() + expires_in*1000);
            
            localStorage.setItem('ACCESS_TOKEN_EXPIRES', expiryDate.toISOString());
        })
    }

    

    Register(username:string, password:string)
    {
        const data = {
            "email": username,
            "password": password,
            "confirmPassword": password
          }
        return axios.post(AppSettings.ApiUrl + 'Account/register', data);
    }

    IsLoggedin():string
    {
        const tokn = localStorage.getItem('ACCESS_TOKEN');
        const toknDate = localStorage.getItem('ACCESS_TOKEN_EXPIRES');
        //console.log('IsLoggedin.tokn', tokn, toknDate, new Date(toknDate), new Date());
        //console.log('IsLoggedin', new Date(toknDate));
        
        if(tokn && new Date(toknDate) > new Date())
            return tokn;
        
        // != null;
        return null;
    }

    GetUserName()
    {
        const tokn = localStorage.getItem('ACCESS_TOKEN') || "";
        return this.parseJwt(tokn)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
    }

    /// Used to get username from JWT token
    parseJwt (token:string):any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    Logout():void{
        localStorage.removeItem('ACCESS_TOKEN');
    }

    LostPassword_SenMail(username:string)
    {
        const data = { "email": username };
        return axios.post(AppSettings.ApiUrl + 'Account/lostPassword/send', data);
    }

    LostPassword_Confirm(model:LostPasswordModel)
    {
        return axios.post(AppSettings.ApiUrl + 'Account/lostPassword/confirm', model);
    }
}

export class LostPasswordModel  {
    public userId: string;
    public token: string;
    public newPassword: string;
    public confirmNewPassword: string;
    public constructor(userId: string, token: string, newPassword:string, confirmNewPassword: string) { 
        this.userId = userId; 
        this.token = token;
        this.newPassword = newPassword;
        this.confirmNewPassword = confirmNewPassword;
    }
}