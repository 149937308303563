import { AuthService } from '@/services/AuthService';
import { AccountService } from '@/services/AccountService';
import { CacheService } from '@/services/CacheService';
import { WebsiteService } from '@/services/website/WebsiteService';
import { TemplateService } from '@/services/website/TemplateService';
import { ChartService } from '@/services/website/ChartService';

export class ServiceLocator {
    Auth = new AuthService();
    Account  = new AccountService();
    Cache   = new CacheService();
    Website = new WebsiteService();
    Template  = new TemplateService();
    Chart  = new ChartService();
    
}
const container = new ServiceLocator();
export default container;