import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import axios from 'axios';

Vue.config.productionTip = false

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response;// Do something with response data
}, function (error) {
    // Do something with response error
    if (error.response.status == 401) {
        console.log("401: redirecting to login");
        //router.push("login"); // if not authorized redirect to /login
        window.location.href = '/login';
    }

    return Promise.reject(error);
});

Vue.filter('mdate', function (value) {
    if (!value) return '';
    return value.toString().substring(0, 10);
});



Vue.filter('utctolocaldt', function (value) {
    if (!value) return '';
    const d = new Date(value.toString());
    let timeOffsetInHours = null;
    if (timeOffsetInHours == null) {
        timeOffsetInHours = (new Date().getTimezoneOffset() / 60) * (-1);
    }
    console.log('timeOffsetInHours', timeOffsetInHours);
    d.setHours(d.getHours() + timeOffsetInHours);
    return d.toLocaleString(); //.toLocaleDateString();

    //return value.toString().substring(0,16).replace("T", " ");
});

Vue.filter('localdt', function (value) {
    if (!value) return '';
    const d = new Date(value.toString());
    return d.toLocaleString();
});

function timeSince(date: Date) {

    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

Vue.filter('timesince', function (value) {
    if (!value) return '';

    if (value.getMonth) {
        return timeSince(value);
    } 

    const d = new Date(value.toString());
    return timeSince(d);
});

const booleanFilter = function (value, trueText, falseText) {
    return value ? trueText || 'Yes' : falseText || 'No';
}

Vue.filter('boolean', booleanFilter);
Vue.filter('yesno', booleanFilter);

const translateFilter = function (value, trueText, falseText) {

    return value + ' => ' + trueText + ' => ' + falseText;// + translate.Translate;
}

Vue.filter('translate', translateFilter);


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
