import { ApiService } from '@/services/ApiService';
import { AppSettings } from '@/services/AppSettings';

export class AccountService {
        
    api : ApiService = new ApiService();
    
    GetSettings()
    {
        return this.api.get(AppSettings.ApiUrl + 'Account/settings');
    }

    SaveSettings(settings:AccountSettingsModel)
    {
        return this.api.post(AppSettings.ApiUrl + 'Account/settings', settings);
    }

    ChangePassword(settings:ChangePasswordModel)
    {
        return this.api.post(AppSettings.ApiUrl + 'Account/changePassword', settings);
    }
}

export class AccountSettingsModel  {
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public constructor(fn: string, ln: string, pn:string) { 
        this.firstName = fn; 
        this.lastName = ln;
        this.phoneNumber = pn;
    }
}

export class ChangePasswordModel  {
    public oldPassword: string;
    public newPassword: string;
    public confirmPassword: string;
    public constructor(fn: string, ln: string, pn:string) { 
        this.oldPassword = fn; 
        this.newPassword = ln;
        this.confirmPassword = pn;
    }
}