import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
//import Home from '../views/Home.vue'
import Home from '../views/home/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/login/Login.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/auth/resetPassword/ResetPassword.vue')
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/Account.vue')
    },
    {
        path: '/charts',
        name: 'charts',
        component: () => import('@/views/board/Charts.vue')
    },
    {
        path: '/board',
        name: 'board',
        component: () => import('@/views/board/Board.vue'),
        children: [
            {
                path: 'website/:id/:label',
                component: () => import('@/views/board/WebsiteBoard.vue')
            },
        ]
    },
    {
        path: '/website/:id',
        name: 'website',
        component: () => import('@/views/website/Website.vue')
    },
    {
        path: '/WebsiteAlerts/:id',
        name: 'WebsiteAlerts',
        component: () => import('@/views/website/WebsiteAlerts.vue')
    },
    
    {
        path: '/admin/',
        name: 'adminPanel',
        component: () => import('@/views/admin/dashboard/AdminPanel.vue'),
        children: [
            {
                path: '/admin/users',
                component: () => import('@/views/admin/users/Users.vue'),
                children: [
                    {
                        path: '/admin/users/:id',
                        name: 'userDetails',
                        component: () => import('@/views/admin/users/UserDetails.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        redirect: '/' // redirect if not exists
    }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//console.log("NEWrouter");
export default router
