import axios, { AxiosResponse } from 'axios';
import { CacheService } from './CacheService';

export class ApiService {
    cache : CacheService = new CacheService();
    
    get(url:string)
    {
        return axios.get(url, this.getAuthHeaders());
    }

    getWithCache(url:string, cacheName:string, timeInMinutes:number):Promise<any>
    {
        //this.cache.clear();
        let data = this.cache.get(cacheName);
        if(data)
        {
            //console.log('getWithCache from cache', data);
            return new Promise((resolve) => {
                resolve({ data:data });
            })
        }

        return axios.get(url, this.getAuthHeaders()).then((resp)=>{
            
            data = resp.data;
            //console.log('getWithCache from api', data);
            this.cache.setObject(cacheName, data, timeInMinutes);
            return new Promise((resolve) => {
                resolve({ data:data });
            });
        });
    }

    post(url:string, data?:any)
    {
        return axios.post(url, data, this.getAuthHeaders());
    }

    delete(url:string)
    {
        return axios.delete(url, this.getAuthHeaders());
    }

    patch(url:string, data?:any)
    {
        return axios.patch(url, data, this.getAuthHeaders());
    }

    put(url:string, data?:any)
    {
        return axios.put(url, data, this.getAuthHeaders());
    }

    
    getAuthHeaders()
    {
        return { headers:{ 'old-authorization':'bearer '+localStorage.getItem('ACCESS_TOKEN') }};
    }
}
