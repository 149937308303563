import { ApiService } from '@/services/ApiService';
import { AppSettings } from '@/services/AppSettings';

export class ChartService {
        
    api : ApiService = new ApiService();
    


    GetDay(websiteId:string)
    {
        return this.api.get(AppSettings.ApiUrl + 'WebsiteChart/website/day?websiteId='+websiteId);
    }

    GetByWebsite(websiteId:string)
    {
        return this.api.get(AppSettings.ApiUrl + '/api/website-chart?websiteId='+websiteId);
    }
    
}
